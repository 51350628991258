footer {
  background-color: #312F3B;
  padding: 34px 0 32px;

  @media (max-width: 767px) {
    padding: 20px 0 86px;
  }
  a {
    text-decoration: none;
  }
  .navigation {
    ul {
      margin: 0;
      padding: 0;
      width: 100%;
      list-style-type: none;
      text-align: center;

      li {
        position: relative;
        display: inline-block;

        @media (max-width: 767px) {
          display: block;
        }
        &:not(:first-child) {
          margin-left: 50px;

          @media (max-width: 1200px) {
            margin-left: 25px;
          }
          @media (max-width: 991px) {
            margin-left: 20px;
          }
          @media (max-width: 767px) {
            margin-left: 0;
            padding-top: 12px;
          }
        }
        &:not(:last-child) {
          margin-right: 50px;

          @media (max-width: 1200px) {
            margin-right: 25px;
          }
          @media (max-width: 991px) {
            margin-right: 20px;
          }
          @media (max-width: 767px) {
            margin-right: 0;
            padding-bottom: 12px;
            border-bottom: 1px solid #403E4D;
          }
          &:after {
            position: absolute;
            top: 6px;
            right: -50px;
            height: 12px;
            content: '';
            border-right: 1px solid #403E4D;

            @media (max-width: 1200px) {
              right: -25px;
            }
            @media (max-width: 991px) {
              right: -20px;
            }
            @media (max-width: 767px) {
              display: none;
            }
          }
        }
      }
    }
    a {
      color: #E9EEF4;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.1px;
    }

  }
  .social {
    display: flex;
    justify-content: center;
    margin: 38px 0;
    height: 20px;

    a {
      display: inline-block;
      height: 20px;
      width: 21px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    a:not(:first-child) {
      margin-left: 44px;
    }
    .facebook {
      background-image: url("https://soultime.s3-eu-west-1.amazonaws.com/assets/facebook_white_20x20.svg");
    }
    .insta {
      background-image: url("https://soultime.s3-eu-west-1.amazonaws.com/assets/insta_white_20x20.svg");
    }
    img {
      height: 20px;
    }
  }
  .copyright {
    margin: 0;
    color: #615E73;
    text-align: center;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.3em;
  }
}
