@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-Regular.eot');
    src: local('☺'), url('../fonts/Montserrat/Montserrat-Regular.woff') format('woff'), url('../fonts/Montserrat/Montserrat-Regular.ttf') format('truetype'), url('../fonts/Montserrat/Montserrat-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-Light.eot');
    src: local('☺'), url('../fonts/Montserrat/Montserrat-Light.woff') format('woff'), url('../fonts/Montserrat/Montserrat-Light.ttf') format('truetype'), url('../fonts/Montserrat/Montserrat-Light.svg') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-Medium.eot');
    src: local('☺'), url('../fonts/Montserrat/Montserrat-Medium.woff') format('woff'), url('../fonts/Montserrat/Montserrat-Medium.ttf') format('truetype'), url('../fonts/Montserrat/Montserrat-Medium.svg') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-SemiBold.eot');
    src: local('../fonts/Montserrat'), url('../fonts/Montserrat/Montserrat-SemiBold.woff') format('woff'), url('../fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype'), url('../fonts/Montserrat/Montserrat-SemiBold.svg') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-Bold.eot');
    src: local('../fonts/Montserrat'), url('../fonts/Montserrat/Montserrat-Bold.woff') format('woff'), url('../fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('../fonts/Nunito/Nunito-Regular.eot');
    src: local('☺'), url('../fonts/Nunito/Nunito-Regular.woff') format('woff'), url('../fonts/Nunito/Nunito-Regular.ttf') format('truetype'), url('../fonts/Nunito/Nunito-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('../fonts/Nunito/Nunito-Bold.eot');
    src: local('☺'), url('../fonts/Nunito/Nunito-Bold.woff') format('woff'), url('../fonts/Nunito/Nunito-Bold.ttf') format('truetype'), url('../fonts/Nunito/Nunito-Bold.svg') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('../fonts/Nunito/Nunito-SemiBold.eot');
    src: local('☺'), url('../fonts/Nunito/Nunito-SemiBold.woff') format('woff'), url('../fonts/Nunito/Nunito-SemiBold.ttf') format('truetype'), url('../fonts/Nunito/Nunito-SemiBold.svg') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito Sans';
    src: local('☺'), url('../fonts/NunitoSans/NunitoSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito Sans';
    src: local('☺'), url('../fonts/NunitoSans/NunitoSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito Sans';
    src: local('☺'), url('../fonts/NunitoSans/NunitoSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito Sans';
    src: local('☺'), url('../fonts/NunitoSans/NunitoSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito Sans';
    src: local('☺'), url('../fonts/NunitoSans/NunitoSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito Sans';
    src: local('☺'), url('../fonts/NunitoSans/NunitoSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito Sans';
    src: local('☺'), url('../fonts/NunitoSans/NunitoSans-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito Sans';
    src: local('☺'), url('../fonts/NunitoSans/NunitoSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito Sans';
    src: local('☺'), url('../fonts/NunitoSans/NunitoSans-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Quattrocento Sans';
    src: url('../fonts/QuattrocentroSans/QuattrocentoSans-Regular.ttf') format('truetype');
    src: local('☺'), url('../fonts/QuattrocentroSans/quattrocentosans-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Quattrocento Sans';
    src: url('../fonts/QuattrocentroSans/QuattrocentoSans-Bold.ttf') format('truetype');
    src: local('☺'), url('../fonts/QuattrocentroSans/quattrocentosans-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue';
    src: local('☺'), url('../fonts/BebasNeue/BebasNeue-Regular.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Caveat';
    src: local('☺'), url('../fonts/Caveat/Caveat-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Caveat';
    src: local('☺'), url('../fonts/Caveat/Caveat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Caveat';
    src: local('☺'), url('../fonts/Caveat/Caveat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Caveat';
    src: local('☺'), url('../fonts/Caveat/Caveat-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
