@import "fonts";

@import "mixins";

@import "header";

@import "footer";

$blur-background-default: #35333F;

html, body {
  height: 100%;
}

* {
  outline: none;
}

body {
  overflow-x: hidden;
  margin: 0;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #E9EEF4;

  * {
    color: #E9EEF4;
  }
}

.background-mountain {
  height: 100vh;
  padding: 0;
  background-image: url("https://soultime.s3-eu-west-1.amazonaws.com/assets/mountain_2k_dark.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (max-width: 1199px) {
    background-image: url("https://soultime.s3-eu-west-1.amazonaws.com/assets/mountain_HD_dark.jpg");
  }
  @media (max-width: 575px) {
    background-image: url("https://soultime.s3-eu-west-1.amazonaws.com/assets/mountain+Image+Phone_new.jpg");
  }
}

.blur-background {
  background-color: $blur-background-default;

  &.chrome-safari {
    background-color: rgba(0,6,17,0.22);
    backdrop-filter: blur(80px);
    -webkit-backdrop-filter: blur(80px);
  }
}

.full-screen-fixed {
  position: fixed;
  z-index: -1;
  width: 100%;
}

a {
  color: #6FC7FF;
  text-decoration: underline;
  font-weight: 400;

  &:hover {
    color: #6FC7FF;
    text-decoration: none;
  }
}

.btn-gradient {
  width: 280px;
  height: 40px;
  text-align: center;
  color: #fff;
  border: unset;
  border-radius: 27px;
  background: no-repeat, center, url("https://soultime.s3-eu-west-1.amazonaws.com/assets/button-gradient-40x280.svg");
}

button {
  &:focus {
    outline: unset;
  }
}

form {
  .forms-row {
    position: relative;

    .forms-row-error {
      position: absolute;
      left: 0;
      bottom: -24px;
      font-family: "Nunito Sans", Arial, sans-serif;
      font-size: 12px;
      line-height: 24px;
      color: #EF3561;

      @media (max-width: 575px) {
        font-size: 10px;
      }
    }
  }
  button.btn-gradient {
    @include transition(0.5s);
    pointer-events: none;
    opacity: 0.5;

    &.is-active {
      pointer-events: auto;
      opacity: 1;
    }
  }
}

.server-error {
  width: 40%;
  max-width: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2;
  background-color: #35333F;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 500;

  @media (max-width: 768px) {
    width: 60%;
  }
  @media (max-width: 575px) {
    width: 80%;
  }

  .msg {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-size: 16px;
    line-height: 24px;
  }
  .close {
    height: 50px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #74CDFF;
    cursor: pointer;
    border-top: 2px solid #2E2D35;
    font-size: 16px;
    line-height: 24px;
  }
}

.top-url {
  position: fixed;
  z-index: 10;
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0  104px;
  background-image: linear-gradient(#000, transparent);

  @media (max-width: 991px) {
    padding: 0 60px;
  }
  .header-logo-container {
    display: flex;
    align-items: center;

    .header-logo {
      height: 40px;
      width: 139px;
      background-image: url("https://soultime.s3-eu-west-1.amazonaws.com/assets/soultime_logo_145x42.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    .header-path {
      padding-left: 32px;
      padding-top: 5px;
      text-transform: uppercase;
      color: #E9EEF4;
      font-size: 14px;
      span {
        display: block;
        position: relative;

        &:after {
          content: '|';
          position: absolute;
          left: -16px;
          color: #615E73;
          opacity: 0.5;
          transform: rotate(19deg);
          font-size: 16px;
        }
      }
    }
  }

  img, a {
    height: 40px;
    display: inline-block;
  }
  a:first-child {
    margin-right: 20px;
  }
}

.bottom-url {
  position: fixed;
  z-index: 1;
  bottom: 0;
  height: 86px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0  12px;
  background-image: linear-gradient(transparent, #000);

  img, a {
    display: inline-block;
    height: 43px;
    width: 142px;
  }
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;

  a {
    margin-bottom: 28px;
    padding: 8px;
    text-decoration: none;
    color: #fff;

    &:hover {
      text-decoration: none;
      color: #fff;
    }
    &.fb {
      width: 280px;
      height: 40px;
      color: #fff;
      border-radius: 27px;
      position: relative;
      background-color: #2E77F2;
      padding: 12px 12px 12px 59px;
      font-family: Helvetica, Arial, sans-serif;
      font-weight: 700;
      line-height: 18px;
      text-align: left;

      .fb-icon {
        position: absolute;
        width: 26px;
        height: 26px;
        left: 11px;
        top: 7px;
        background: no-repeat, scroll, center, url("https://soultime.s3-eu-west-1.amazonaws.com/assets/fb_logo_26x26.svg");
      }
    }
    &.sign-up {
      margin-bottom: 20px;
    }
    &.login {
      margin-bottom: 0;
      background: none;
    }
  }
}

.privacy {
  display: flex;
  justify-content: center;
  padding-bottom: 53px;
  font-family: "Nunito", Arial, sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-align: center;

  @media (max-width: 575px) {
    flex-direction: column;
  }
}
